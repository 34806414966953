//import React from 'react';
//import { HydraAdmin } from '@api-platform/admin';

//export default () => <HydraAdmin entrypoint={process.env.REACT_APP_API_ENTRYPOINT}/>;

import React from "react";
import {Link, Redirect, Route} from "react-router-dom";
import {
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
  ResourceGuesser
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import Dashboard from './components/dashboard/Dashboard';
import styles from './utils/custom.css';
import {
  ActivityList, ActivityShow, ActivityCreate, ActivityEdit,
  CategoryList, CategoryShow, CategoryCreate, CategoryEdit,
  ContentList, ContentShow, ContentCreate, ContentEdit,
  ClientVersionList, ClientVersionShow, ClientVersionCreate, ClientVersionEdit,
  LocationList, LocationShow, LocationCreate, LocationEdit,
  NotificationList, NotificationShow, NotificationCreate, NotificationEdit,
  NotifygroupList, NotifygroupShow, NotifygroupCreate, NotifygroupEdit,
  NotifyqueueList, NotifyqueueShow, NotifyqueueCreate, NotifyqueueEdit,
  UserList, UserShow, UserCreate, UserEdit,
} from './tables';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = { Authorization: `Bearer ${window.localStorage.getItem("token")}` };
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({ api }),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                              return window.localStorage.getItem("token") ? window.location.reload() : <Redirect to="/login" />
                            }} />
                        ],
                });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true, true);

export default () => (
    <HydraAdmin
dataProvider={ dataProvider }
authProvider={ authProvider }
entrypoint={ process.env.REACT_APP_API_ENTRYPOINT }
    >
      <Dashboard />
      <ResourceGuesser name="activities" list={ActivityList} show={ActivityShow} create={ActivityCreate} edit={ActivityEdit} />
      <ResourceGuesser name="ads" />
      <ResourceGuesser name="advertisers" />
      {/*<ResourceGuesser name="appointments" />*/}
      <ResourceGuesser name="categories" list={CategoryList} show={CategoryShow} create={CategoryCreate} edit={CategoryEdit} />
      <ResourceGuesser name="locations" list={LocationList} show={LocationShow} create={LocationCreate} edit={LocationEdit} />
      {/*<ResourceGuesser name="profiles"/>*/}
      {/*<ResourceGuesser name="searches"/>*/}
      {/*<ResourceGuesser name="users"/>*/}
      <ResourceGuesser name="notifications" list={NotificationList} show={NotificationShow} create={NotificationCreate} edit={NotificationEdit} />
      <ResourceGuesser name="notifygroups" list={NotifygroupList} show={NotifygroupShow} create={NotifygroupCreate} edit={NotifygroupEdit} />
      <ResourceGuesser name="notifyqueues" />
      <ResourceGuesser name="contents" list={ContentList} show={ContentShow} create={ContentCreate} edit={ContentEdit} />
      <ResourceGuesser name="client_versions" list={ClientVersionList} show={ClientVersionShow} create={ClientVersionCreate} edit={ClientVersionEdit} />
      {/*<ResourceGuesser name="users" list={UserList} show={UserShow} create={UserCreate} edit={UserEdit} />*/}
      <Link to="/" style={{paddingTop:'3rem',display:'block'}}>Dashboard</Link>
    </HydraAdmin>
);
