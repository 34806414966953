import React, {useEffect, useState} from 'react';

function NotifyGroupStats(props) {
  const { record } = props;
  const [aggregates, setAggregates] = useState({});
  const [error, setError] = useState('');
  const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
  const fetchHeaders = { Authorization: `Bearer ${window.localStorage.getItem("token")}` };

    useEffect(() => {
      const requestOptions = {
        method: 'GET',
        headers: fetchHeaders
      };

      fetch(`${entrypoint}/api/notifygroupcount/${record.originId}`, requestOptions)
        .then(response => response.json())
        .then(data => setAggregates(data))
        .catch(setError('Error'));
    }, []);

  return (
    <div>{record.name} ({aggregates.count} dudes)</div>
  );
}

export default NotifyGroupStats;
