import React from "react";
import {
  ListGuesser,
  ShowGuesser,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser
} from "@api-platform/admin";
import {
  ReferenceField, ReferenceInput, AutocompleteInput, ChipField, TextInput
} from "react-admin";
import NotifyGroupStats from "../components/notifyGroupStats/NotifyGroupStats";

export const NotificationList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
    <FieldGuesser source="title" />
    <FieldGuesser source="message" />
    <ReferenceField label="Notify Group" source="notifygroup" reference="notifygroups">
      <ChipField source="name" />
    </ReferenceField>
  </ListGuesser>
);

export const NotificationShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser addLabel="true" label="Name" source="name" />
    <FieldGuesser addLabel="true" label="Title" source="title" />
    <FieldGuesser addLabel="true" label="Message" source="message" />
    <ReferenceField label="Notify Group" source="notifygroup" reference="notifygroups">
      <NotifyGroupStats />
    </ReferenceField>
    <FieldGuesser addLabel="true" label="Send a test to test users" source="sendtest" />
    <FieldGuesser addLabel="true" label="Schedule send time" source="schedule" />
    <FieldGuesser addLabel="true" label="Ready to set queue" source="queue" />
    <FieldGuesser addLabel="true" label="Total users" source="total" />
    <FieldGuesser addLabel="true" label="Total sent" source="sent" />
    <FieldGuesser addLabel="true" label="Failed sent" source="failed" />
    <FieldGuesser addLabel="true" label="Group already queued" source="queued" />
  </ShowGuesser>
);

export const NotificationCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser addLabel="true" label="Name" source="name" />
    <InputGuesser addLabel="true" label="Title" source="title" />
    <TextInput multiline resettable addLabel="true" label="Message" source="message" />
    <ReferenceInput
      source="notifygroup"
      reference="notifygroups"
      label="Notify Group"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <InputGuesser addLabel="true" label="Send a test to test users" source="sendtest" defaultValue={false} />
    <InputGuesser addLabel="true" label="Schedule send time" source="schedule" />
    <InputGuesser addLabel="true" label="Ready to set queue" source="queue" defaultValue={false} />
  </CreateGuesser>
);

export const NotificationEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser addLabel="true" label="Name" source="name" />
    <InputGuesser addLabel="true" label="Title" source="title" />
    <TextInput multiline resettable addLabel="true" label="Message" source="message" />
    <ReferenceInput
      source="notifygroup"
      reference="notifygroups"
      label="Notify Group"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <InputGuesser addLabel="true" label="Send a test to test users" source="sendtest" />
    <InputGuesser addLabel="true" label="Schedule send time" source="schedule" />
    <InputGuesser addLabel="true" label="Ready to set queue" source="queue" />
  </EditGuesser>
);

