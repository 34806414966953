import React from "react";
import {
  ListGuesser,
  ShowGuesser,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser
} from "@api-platform/admin";
import { ReferenceArrayField, SingleFieldList, ChipField, AutocompleteArrayInput, Datagrid, TextField,
  ReferenceArrayInput, ShowButton, TextInput
} from "react-admin";

export const LocationList = props => (
  <ListGuesser {...props}>
    <FieldGuesser label="Name" source="name" />
    <FieldGuesser label="Address" source="address" />
    <FieldGuesser label="Bezahlt" source="payed" />
    <ReferenceArrayField label="Activities" reference="activities" source="activities">
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ListGuesser>
);

export const LocationShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser addLabel="true" label="Name" source="name" />
    <FieldGuesser addLabel="true" label="description" source="description" />
    <FieldGuesser addLabel="true" label="Address" source="address" />
    <FieldGuesser addLabel="true" label="Bezahlt" source="payed" />
    <FieldGuesser addLabel="true" label="Latitude" source="latitude" />
    <FieldGuesser addLabel="true" label="Longitude" source="longitude" />
    <FieldGuesser addLabel="true" label="Distance" source="distance" />
    <FieldGuesser addLabel="true" label="Phone" source="phone" />
    <FieldGuesser addLabel="true" label="Email" source="email" />
    <FieldGuesser addLabel="true" label="Web Url" source="weburl" />
    <FieldGuesser addLabel="true" label="Picture Filename" source="picfilename" />
    <ReferenceArrayField label="Activities" reference="activities" source="activities">
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ShowButton />
      </Datagrid>
    </ReferenceArrayField>
  </ShowGuesser>
);

export const LocationCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser label="Name" source="name" />
    <TextInput multiline resettable label="description" source="description" />
    <InputGuesser label="Address" source="address" />
    <InputGuesser label="Bezahlt" source="payed" />
    <InputGuesser label="Latitude" source="latitude" />
    <InputGuesser label="Longitude" source="longitude" />
    <InputGuesser label="Distance" source="distance" />
    <InputGuesser label="Phone" source="phone" />
    <InputGuesser label="Email" source="email" />
    <InputGuesser label="Web Url" source="weburl" />
    <InputGuesser label="Picture Filename" source="picfilename" />
    <ReferenceArrayInput
      label="Activities"
      reference="activities"
      source="activities"
      filterToQuery={searchText => ({ listname: searchText })}
      enableGetChoices={({ listname }) => (listname ? listname.length >= 2 : false)}
    >
      <AutocompleteArrayInput
        shouldRenderSuggestions={value => value.length >= 2}
      />
    </ReferenceArrayInput>
  </CreateGuesser>
);

export const LocationEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser label="Name" source="name" />
    <TextInput multiline resettable label="description" source="description" />
    <InputGuesser label="Address" source="address" />
    <InputGuesser label="Bezahlt" source="payed" />
    <InputGuesser label="Latitude" source="latitude" />
    <InputGuesser label="Longitude" source="longitude" />
    <InputGuesser label="Distance" source="distance" />
    <InputGuesser label="Phone" source="phone" />
    <InputGuesser label="Email" source="email" />
    <InputGuesser label="Web Url" source="weburl" />
    <InputGuesser label="Picture Filename" source="picfilename" />
    <ReferenceArrayInput
      label="Activities"
      reference="activities"
      source="activities"
      filterToQuery={searchText => ({ listname: searchText })}
      enableGetChoices={({ listname }) => (listname ? listname.length >= 2 : false)}
    >
      <AutocompleteArrayInput optionText="listname"
                              shouldRenderSuggestions={value => value.length >= 2}
      />
    </ReferenceArrayInput>
  </EditGuesser>
);
