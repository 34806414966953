import React from "react";
import {
  ListGuesser,
  ShowGuesser,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser
} from "@api-platform/admin";
import { ReferenceArrayField, SingleFieldList, ChipField
} from "react-admin";

export const CategoryList = props => (
  <ListGuesser {...props}>
    <FieldGuesser label="Name" source="name" />
    <FieldGuesser label="Translation Key" source="i18nkey" />
  </ListGuesser>
);

export const CategoryShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser label="Name" source="name" />
    <ReferenceArrayField label="Activities" reference="activities" source="activities">
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
    <FieldGuesser label="Translation Key" source="i18nkey" />
  </ShowGuesser>
);

export const CategoryCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser label="Name" source="name" />
    <InputGuesser label="Translation Key" source="i18nkey" />
  </CreateGuesser>
);

export const CategoryEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser label="Name" source="name" />
    <InputGuesser label="Translation Key" source="i18nkey" />
  </EditGuesser>
);
