import React from "react";
import {
  ListGuesser,
  ShowGuesser,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser
} from "@api-platform/admin";
import {
  ReferenceArrayField, ReferenceArrayInput, AutocompleteArrayInput, Datagrid, ShowButton, TextField, SelectInput
} from "react-admin";
import NotifyGroupStats from "../components/notifyGroupStats/NotifyGroupStats";

export const NotifygroupList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
    <FieldGuesser source="description" />
  </ListGuesser>
);

export const NotifygroupShow = props => (
  <ShowGuesser {...props}>
    <NotifyGroupStats />
    <FieldGuesser addLabel="true" label="Description" source="description" />
    <FieldGuesser addLabel="true" label="From Version" source="minversion" />
    <FieldGuesser addLabel="true" label="Until Version" source="maxversion" />
    <FieldGuesser addLabel="true" label="Language" source="language" />
    <FieldGuesser addLabel="true" label="Gender" source="gender" />
    <FieldGuesser addLabel="true" label="From Birthday" source="minbirthday" />
    <FieldGuesser addLabel="true" label="To Birthday" source="maxbirthday" />
    <FieldGuesser addLabel="true" label="Last Latitude" source="lastlatitude" />
    <FieldGuesser addLabel="true" label="Last Longitude" source="lastlongitude" />
    <FieldGuesser addLabel="true" label="Distance" source="distance" />
    <FieldGuesser addLabel="true" label="Has active Search" source="hasactivesearch" />
    <FieldGuesser addLabel="true" label="Has active alerts" source="hasactivealert" />
    <ReferenceArrayField label="Activities" reference="activities" source="activities">
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ShowButton />
      </Datagrid>
    </ReferenceArrayField>
  </ShowGuesser>
);

export const NotifygroupCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser addLabel="true" label="Name" source="name" />
    <InputGuesser addLabel="true" label="Description" source="description" />
    <InputGuesser addLabel="true" label="From Version" source="minversion" />
    <InputGuesser addLabel="true" label="Until Version" source="maxversion" />
    <SelectInput addLabel="true" label="Language" source="language" choices={[
      { id: '', name: 'Any' },
      { id: 'LNG_DE', name: 'German' },
      { id: 'LNG_EN', name: 'English' },
    ]} />
    <SelectInput addLabel="true" label="Gender" source="gender" choices={[
      { id: '', name: 'Any' },
      { id: 1, name: 'Boy' },
      { id: 2, name: 'Girl' },
    ]} />
    <InputGuesser addLabel="true" label="From Birthday" source="minbirthday" />
    <InputGuesser addLabel="true" label="To Birthday" source="maxbirthday" />
    <InputGuesser addLabel="true" label="Last Latitude" source="lastlatitude" />
    <InputGuesser addLabel="true" label="Last Longitude" source="lastlongitude" />
    <InputGuesser addLabel="true" label="Distance" source="distance" />
    <InputGuesser addLabel="true" label="Has active Search" source="hasactivesearch" />
    <InputGuesser addLabel="true" label="Has active alerts" source="hasactivealert" />
    <ReferenceArrayInput
      label="Activities"
      reference="activities"
      source="activities"
      filterToQuery={searchText => ({ listname: searchText })}
      enableGetChoices={({ listname }) => (listname ? listname.length >= 2 : false)}
    >
      <AutocompleteArrayInput
        shouldRenderSuggestions={value => value.length >= 2}
      />
    </ReferenceArrayInput>
  </CreateGuesser>
);

export const NotifygroupEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser addLabel="true" label="Name" source="name" />
    <InputGuesser addLabel="true" label="Description" source="description" />
    <InputGuesser addLabel="true" label="From Version" source="minversion" />
    <InputGuesser addLabel="true" label="Until Version" source="maxversion" />
    <SelectInput addLabel="true" label="Language" source="language" choices={[
      { id: '', name: 'Any' },
      { id: 'LNG_DE', name: 'German' },
      { id: 'LNG_EN', name: 'English' },
    ]} />
    <SelectInput addLabel="true" label="Gender" source="gender" choices={[
      { id: '', name: 'Any' },
      { id: 1, name: 'Boy' },
      { id: 2, name: 'Girl' },
    ]} />
    <InputGuesser addLabel="true" label="From Birthday" source="minbirthday" />
    <InputGuesser addLabel="true" label="To Birthday" source="maxbirthday" />
    <InputGuesser addLabel="true" label="Last Latitude" source="lastlatitude" />
    <InputGuesser addLabel="true" label="Last Longitude" source="lastlongitude" />
    <InputGuesser addLabel="true" label="Distance" source="distance" />
    <InputGuesser addLabel="true" label="Has active Search" source="hasactivesearch" />
    <InputGuesser addLabel="true" label="Has active alerts" source="hasactivealert" />
    <ReferenceArrayInput
      label="Activities"
      reference="activities"
      source="activities"
      filterToQuery={searchText => ({ listname: searchText })}
      enableGetChoices={({ listname }) => (listname ? listname.length >= 2 : false)}
    >
      <AutocompleteArrayInput
        shouldRenderSuggestions={value => value.length >= 2}
      />
    </ReferenceArrayInput>
  </EditGuesser>
);

