import React from "react";
import {
  ListGuesser,
  ShowGuesser,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser
} from "@api-platform/admin";
import { TextInput, SelectInput } from "react-admin";

export const ContentList = props => (
  <ListGuesser {...props}>
    <FieldGuesser label="Content Key" source="key" />
    <FieldGuesser label="Language" source="language" />
  </ListGuesser>
);

export const ContentShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser addLabel="true" label="Content Key" source="key" />
    <FieldGuesser addLabel="true" label="Language" source="language" />
    <FieldGuesser addLabel="true" label="Body Text" source="body" />
  </ShowGuesser>
);

export const ContentCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser label="Content Key" source="key" />
    <SelectInput label="Activity Type" source="language" choices={[
      { id: 'de', name: 'Deutsch' },
      { id: 'en', name: 'English' },
    ]} />
    <TextInput multiline resettable label="Body Text" source="body" />
  </CreateGuesser>
);

export const ContentEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser label="Content Key" source="key" />
    <SelectInput label="Activity Type" source="language" choices={[
      { id: 'de', name: 'Deutsch' },
      { id: 'en', name: 'English' },
    ]} />
    <TextInput multiline resettable label="Body Text" source="body" />
  </EditGuesser>
);
