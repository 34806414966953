import React from "react";
import {
  ListGuesser,
  ShowGuesser,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser
} from "@api-platform/admin";
import { SelectInput } from "react-admin";

export const ClientVersionList = props => (
  <ListGuesser {...props}>
    <FieldGuesser label="ClientVersion" source="version" />
    <FieldGuesser label="Platform" source="platform" />
    <FieldGuesser label="Recommend Update" source="recommendUpdate" />
    <FieldGuesser label="Force Update" source="forceUpdate" />
  </ListGuesser>
);

export const ClientVersionShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser label="ClientVersion" source="version" />
    <FieldGuesser label="Platform" source="platform" />
    <FieldGuesser label="Recommend Update" source="recommendUpdate" />
    <FieldGuesser label="Force Update" source="forceUpdate" />
  </ShowGuesser>
);

export const ClientVersionCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser label="ClientVersion" source="version" />
    <SelectInput label="Platform" source="platform" choices={[
      { id: 'ios', name: 'iOS' },
      { id: 'android', name: 'Android' },
    ]} />
    <InputGuesser label="Recommend Update" initialValue={false} source="recommendUpdate" />
    <InputGuesser label="Force Update" initialValue={false} source="forceUpdate" />
  </CreateGuesser>
);

export const ClientVersionEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser label="ClientVersion" source="version" />
    <SelectInput label="Platform" source="platform" choices={[
      { id: 'ios', name: 'iOS' },
      { id: 'android', name: 'Android' },
    ]} />
    <InputGuesser label="Recommend Update" source="recommendUpdate" />
    <InputGuesser label="Force Update" source="forceUpdate" />
  </EditGuesser>
);
