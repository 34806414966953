import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TabPanel } from './elements';
import {Link, useLocation} from "react-router-dom";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    border: '1px solid #f39325'
  },
  cardHeader: {
    backgroundColor: '#f39325',
    color: 'white',
    fontSize: '0.8rem',
    padding: '4px'
  },
  cardBody: {
    padding: '4px !important'
  }
}));

function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [aggregates, setAggregates] = useState({});
  const [error, setError] = useState('');
  const [collapseActivities, setCollapseActivities] = useState(true);
  const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
  const fetchHeaders = { Authorization: `Bearer ${window.localStorage.getItem("token")}` };
  const location = useLocation();
//  console.log(location.pathname);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: fetchHeaders
    };

    fetch(`${entrypoint}/api/dashboard`, requestOptions)
      .then(response => response.json())
      .then(data => {setAggregates(data); console.log(data)})
      .catch(setError('Error'));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
    {'/undefined' === location.pathname &&
      <>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="User" {...a11yProps(0)} />
          <Tab label="Search" {...a11yProps(1)} />
          <Tab label="Ads" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Total registration" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.users}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Verified user / Login user" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.verifiedUsers} / {aggregates.loginUsers}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Total user with search" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.usersWithSearch}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Total sessions" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.sessions}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Active user" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.activeUsers}</>}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" align="center">Last 7 days</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="New user" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.newUsers}</>}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" align="center">Today</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Deleted user" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.deletedUsers}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Deleted Sessions" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.deletedSessions}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Session / user" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates.avgSessions && <>{aggregates.avgSessions.toLocaleString(navigator.language, {maximumFractionDigits: 1})}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Searches / user" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates.searchesProUser && <>{aggregates.searchesProUser.toLocaleString(navigator.language, {maximumFractionDigits: 1})}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Gender" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="h4" color="textSecondary" component="h4" align="center">
                      {aggregates && <>{aggregates.boys}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">Men</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h4" color="textSecondary" component="h4" align="center">
                      {aggregates && <>{aggregates.girls}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">Women</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Platform" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="h4" color="textSecondary" component="h4" align="center">
                      {aggregates && <>{aggregates.android}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p"
                                align="center">({aggregates.androidabs})</Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">Android</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h4" color="textSecondary" component="h4" align="center">
                      {aggregates && <>{aggregates.ios}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p"
                                align="center">({aggregates.iosabs})</Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">iOS</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Age" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <Typography variant="h6" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g1value}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g1label}</>}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h6" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g2value}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g2label}</>}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h6" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g3value}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g3label}</>}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h6" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g4value}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g4label}</>}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h6" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g5value}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g5label}</>}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h6" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g6value}%</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" align="center">
                      {aggregates && <>{aggregates.g6label}</>}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Total searches" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.searches}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Total matches" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.matches}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Total searches type 2" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.searchesType2}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Deleted Searches" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                <Typography variant="h4" color="textSecondary" component="h4" align="center">
                  {aggregates && <>{aggregates.deletedSearches}</>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader title="Top activities" className={classes.cardHeader}
                          titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
              <CardContent className={classes.cardBody}>
                {aggregates.percentActivities && aggregates.percentActivities.map((activity, index) =>
                  <Typography key={index} variant="body2" color="textSecondary" component="p" align="center"
                              style={index > 7 && collapseActivities ? {'display': 'none'} : {}}>
                    <b>{activity.name}</b>
                    <span><small>({activity.portion.toLocaleString(navigator.language, {maximumFractionDigits: 2})}%)</small></span>
                  </Typography>
                )}
                <Typography variant="body2" color="textSecondary" align="center">
                  {collapseActivities && <Link to="#" onClick={() => setCollapseActivities(false)}>See all</Link>}
                  {!collapseActivities && <Link to="#" onClick={() => setCollapseActivities(true)}>See less</Link>}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader title="Accepted invit." className={classes.cardHeader}
                              titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
                  <CardContent className={classes.cardBody}>
                    <Typography variant="h4" color="textSecondary" component="h4" align="center">
                      {aggregates && <>{aggregates.acceptedInvit}</>}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader title="Active searches" className={classes.cardHeader}
                              titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
                  <CardContent className={classes.cardBody}>
                    <Typography variant="h4" color="textSecondary" component="h4" align="center">
                      {aggregates && <>{aggregates.activeSearches}</>}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader title="Active searches type 2" className={classes.cardHeader}
                              titleTypographyProps={{variant: 'body2', component: 'p', align: 'center'}}/>
                  <CardContent className={classes.cardBody}>
                    <Typography variant="h4" color="textSecondary" component="h4" align="center">
                      {aggregates && <>{aggregates.activeSearchesType2}</>}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      </>
    }
    </div>
  );
}

export default SimpleTabs;
