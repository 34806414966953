import React from "react";
import {
  ListGuesser,
  ShowGuesser,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser
} from "@api-platform/admin";
import { ReferenceArrayField, ReferenceField, ReferenceArrayInput, ReferenceInput, AutocompleteArrayInput,
  AutocompleteInput, Datagrid, ShowButton, TextField, SingleFieldList, ChipField, SelectInput, TextInput
} from "react-admin";

export const ActivityList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source="listname" />
    <FieldGuesser source="name" />
    <FieldGuesser source="active" />
    <ReferenceArrayField label="Categories" reference="categories" source="categories">
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
    <FieldGuesser source="activefrom" />
    <FieldGuesser source="activeto" />
    <ReferenceField label="Location Name" source="location" reference="locations">
      <ChipField source="name" />
    </ReferenceField>
    <FieldGuesser label="Bezahlt" source="payed" />
  </ListGuesser>
);

export const ActivityShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser addLabel="true" label="Name" source="name" />
    <FieldGuesser addLabel="true" label="Event Type" source="type" />
    <FieldGuesser addLabel="true" label="Icon Filename" source="iconfilename" />
    <FieldGuesser addLabel="true" label="Active" source="active" />
    <FieldGuesser addLabel="true" label="Event Picture" source="picture" />
    <FieldGuesser addLabel="true" label="Ticket Price" source="ticketprice" />
    <FieldGuesser addLabel="true" label="Ticket Link" source="ticketlink" />
    <FieldGuesser addLabel="true" label="Latitude" source="latitude" />
    <FieldGuesser addLabel="true" label="Longitude" source="longitude" />
    <FieldGuesser addLabel="true" label="Distance" source="distance" />
    <FieldGuesser addLabel="true" label="Active from" source="activefrom" />
    <FieldGuesser addLabel="true" label="Active until" source="activeto" />
    <FieldGuesser addLabel="true" label="Event Start Timestamp" source="eventtimefrom" />
    <FieldGuesser addLabel="true" label="Event End Timestamp" source="eventtimeto" />
    <FieldGuesser addLabel="true" label="Bezahlt" source="payed" />
    <ReferenceField label="Location Name" source="location" reference="locations">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser addLabel="true" label="Properties" source="properties" />
    <FieldGuesser addLabel="true" label="Translation Key" source="i18nkey" />
    <FieldGuesser addLabel="true" label="List Name" source="listname" />
    <ReferenceArrayField label="Categories" reference="categories" source="categories">
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ShowButton />
      </Datagrid>
    </ReferenceArrayField>
  </ShowGuesser>
);

export const ActivityCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser label="Name" source="name" />
    <InputGuesser label="List Name" source="listname" />
    <InputGuesser label="Translation Key" source="i18nkey" />
    <SelectInput label="Activity Type" source="type" choices={[
      { id: 'event', name: 'Event' },
      { id: 'group', name: 'Group Activity' },
      { id: 'default', name: 'Regular Activty' },
    ]} />
    <InputGuesser label="Icon Filename" source="iconfilename" />
    <InputGuesser label="Active" source="active" />
    <InputGuesser label="Event Picture" source="picture" />
    <InputGuesser label="Ticket Price" source="ticketprice" />
    <InputGuesser label="Ticket Link" source="ticketlink" />
    <ReferenceArrayInput
      label="Categories"
      reference="categories"
      source="categories"
      enableGetChoices={({ q }) => (q ? q.length >= 2 : false)}
    >
      <AutocompleteArrayInput
        shouldRenderSuggestions={value => value.length >= 2}
      />
    </ReferenceArrayInput>
    <TextInput multiline resettable label="Description" source="description" />
    <InputGuesser label="Active from" source="activefrom" />
    <InputGuesser label="Active until" source="activeto" />
    <InputGuesser label="Event Start Timestamp" source="eventtimefrom" />
    <InputGuesser label="Event End Timestamp" source="eventtimeto" />
    <InputGuesser label="Bezahlt" source="payed" />
    <InputGuesser label="Latitude" source="latitude" />
    <InputGuesser label="Longitude" source="longitude" />
    <InputGuesser label="Distance" source="distance" />
    <ReferenceInput
      source="location"
      reference="locations"
      label="Location Name"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
);

export const ActivityEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser label="Name" source="name" />
    <InputGuesser label="List Name" source="listname" />
    <InputGuesser label="Translation Key" source="i18nkey" />
    <SelectInput label="Activity Type" source="type" choices={[
      { id: 'event', name: 'Event' },
      { id: 'group', name: 'Group Activity' },
      { id: 'default', name: 'Regular Activty' },
    ]} />
    <InputGuesser label="Icon Filename" source="iconfilename" />
    <InputGuesser label="Active" source="active" />
    <InputGuesser label="Event Picture" source="picture" />
    <InputGuesser label="Ticket Price" source="ticketprice" />
    <InputGuesser label="Ticket Link" source="ticketlink" />
    <ReferenceArrayInput
      label="Categories"
      reference="categories"
      source="categories"
      enableGetChoices={({ q }) => (q ? q.length >= 2 : false)}
    >
      <AutocompleteArrayInput
        shouldRenderSuggestions={value => value.length >= 2}
      />
    </ReferenceArrayInput>
    <TextInput multiline resettable label="Description" source="description" />
    <InputGuesser label="Active from" source="activefrom" />
    <InputGuesser label="Active until" source="activeto" />
    <InputGuesser label="Event Start Timestamp" source="eventtimefrom" />
    <InputGuesser label="Event End Timestamp" source="eventtimeto" />
    <InputGuesser label="Bezahlt" source="payed" />
    <InputGuesser label="Latitude" source="latitude" />
    <InputGuesser label="Longitude" source="longitude" />
    <InputGuesser label="Distance" source="distance" />
    <ReferenceInput
      source="location"
      reference="locations"
      label="Location Name"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </EditGuesser>
);

